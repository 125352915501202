import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from "gatsby"
import SectionTitleMobile from '../../../shared/components/atoms/section-title-mobile/section-title-mobile'
import CustomInputText from '../../../shared/components/atoms/inputText/InputText'
import CustomInputTextArea from '../../../shared/components/atoms/inputTextArea/InputTextArea'
import CustomCheckbox from '../../../shared/components/atoms/custom-checkbox/customCheckbox'
import HeaderContentQuality from '../../molecules/header-content-qualify/header-content-qualify'
import ButtonC from '../../../shared/components/atoms/button/button'
import BreadCrumb from '../../../shared/components/atoms/breadcrumb/breadcrumb'
import LogoQualify from '../../../assets/images/img-calificaciones.svg'
import CustomProgressBar from '../../../shared/components/atoms/custom-progress-bar/custom-progress-bar';
import ProductService from '../../../shared/services/product';
import RelatedQualify from '../../../components/molecules/relacted-qualify/relacted-qualify';
import homeLink from '../../../shared/utils/siteUrl';
import { localStorage } from "../../../shared/utils/storage";
import { Rating } from '@material-ui/lab';
import { Popover } from '@material-ui/core';
import './qualify-content.scss';
import DataPolicy from '../../../shared/components/atoms/data-policy/data-policy';

const QualifyContent = props => {
  const { isMobile, product, dataProduct, parentSlug } = props
  // const [productRelacted, setProductRelacted] = useState(false);
  const mailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  const [successSaveForm, setSuccessSaveForm] = useState(false)
  const [progresBar, setProgresBar] = useState(0)
  const [email, setEmail] = useState("")
  //Valida si el usuario ha calificado antes y llena el check de terminos y condiciones
  const [temrsConditions, setTemrsConditions] = useState(false)
  const [dataPolicies, setDataPolicies] = useState(false)
  const [name, setName] = useState("")
  const [commentary, setCommentaty] = useState("")
  const [activeStep, setActiveStep] = useState(0)
  const [disabledButton, setDisabledButton] = useState(true)
  const [frmPruebasApruebas, setFrmPruebasApruebas] = useState([])

  const objComentarioFinalStep = [
    {
      title: "¿Tienes algún comentario? (Opcional)",
    },
    {
      title: "Gracias por tu opinión",
      description: "Tu calificación ha sido enviada correctamente.",
      icon: <i className="icon-Check f-finish-icon"></i>,
    },
  ]

  const questionsDefault = [
    {
      title: `Para nosotros es muy valioso conocer tu opinión, por favor déjanos tus datos:`,
    },
    {
      title:
        "Para nosotros es muy valioso conocer tu opinión, por favor déjanos tus datos:",
    },
  ]

  const questions =
    product.preguntasCalificacion !== null
      ? questionsDefault.concat(
          product.preguntasCalificacion.concat(objComentarioFinalStep)
        )
      : questionsDefault
  const maxSteps = questions.length

  //To open the propover popup
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  //For open pop up terms and conditions
  const handleClickPopup = event => {
    setAnchorEl(event.currentTarget)
  }

  //For close pop up terms and conditions
  const handleClosePopup = () => {
    setAnchorEl(null)
  }

  const handleNext = (event, index) => {
    event.preventDefault()
    let enableNext = false

    // Validaciones de los cambos de calificar
    if (mailRegex.test(email) && temrsConditions && activeStep === 0) {
      enableNext = true
    }

    if (activeStep === 1 && name !== "" && name !== " ") {
      enableNext = true
    }

    if (
      frmPruebasApruebas[activeStep] !== undefined &&
      frmPruebasApruebas[activeStep] !== null &&
      activeStep > 1
    ) {
      enableNext = true
    }

    if (enableNext) {
      if (
        (activeStep === 0 && (name === "" || name === " ")) ||
        (activeStep >= 1 &&
          activeStep < maxSteps - 3 &&
          (frmPruebasApruebas[activeStep + 1] === undefined ||
            frmPruebasApruebas[activeStep + 1] === null))
      ) {
        setDisabledButton(true)
      }
      setActiveStep(prevActiveStep => prevActiveStep + 1)
      setProgresBar(progresBar + 100 / (maxSteps - 1).toFixed(1))
    }
  }

  const handleBack = () => {
    setDisabledButton(false)
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    setProgresBar(progresBar - 100 / (maxSteps - 1).toFixed(1))
  }

  const handleChangeEmail = event => {
    if (mailRegex.test(event.target.value)) {
      validateTermsAcepted()
      temrsConditions && setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
    setEmail(event.target.value)
  }

  const handleChangeName = event => {
    let newName = event.target.value.replace("  ", " ")
    if (newName !== "" && newName !== " ") {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
    setName(newName)
  }

  const handleChangeTermsAndPolicies = event => {
    const inputName = event.target.name
    let termsState = temrsConditions
    let policiesState = dataPolicies
    if (inputName == "data-policies") {
      setDataPolicies(event.target.checked)
      policiesState = !policiesState
    } else if (inputName == "terms-conditions") {
      setTemrsConditions(event.target.checked)
      termsState = !termsState
    }
    if (mailRegex.test(email) && termsState && policiesState) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
  }

  const handleChangeRating = (event, value, index) => {
    if (value !== null && value !== undefined) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
    frmPruebasApruebas[index] = value
    setFrmPruebasApruebas(frmPruebasApruebas)
  }

  const handleChangeCommentary = event => {
    let newCommentary = event.target.value.replace("  ", " ")
    setCommentaty(newCommentary.slice(0, 300))
  }

  const handleSendForm = () => {
    setDisabledButton(true)
    var newItems = []

    product.preguntasCalificacion.forEach((item, index) => {
      newItems.push({
        rate: frmPruebasApruebas[index + 2],
        item: item.nombreCategoria,
      })
    })

    let payload = {
      cmsId: product.idDelProducto,
      name: name,
      commentary: commentary,
      email: email,
      items: newItems,
    }

    ProductService.postRate(payload)
      .then(res => {
        if (200 === res.status) {
          setDisabledButton(false)
          setActiveStep(prevActiveStep => prevActiveStep + 1)
          setProgresBar(progresBar + 100 / (maxSteps - 1).toFixed(1))
        } else {
          setDisabledButton(false)
          console.log(res)
        }
      })
      .catch(e => {
        setDisabledButton(false)
        console.log(e)
      })
  }

  // Busca en el local storage la variable userQualify para saber si el usuario ha calificado, sino consume el servicio para verificar en bd
  const validateTermsAcepted = () => {
    let userQualify = localStorage.getItem("userQualify")
    if (!userQualify) {
      ProductService.getRateUser(email)
        .then(res => {
          if (res.data) {
            setTemrsConditions(true)
            localStorage.setItem("userQualify", true)
            setDisabledButton(false)
          } else {
            setTemrsConditions(false)
          }
        })
        .catch(e => console.log(e))
    } else if (userQualify) {
      setTemrsConditions(true)
    }
  }

  useEffect(() => {
    // validateTermsAcepted()
  }, [])

  const policiesInfo = useStaticQuery(
    graphql`
      query QualifyDataPoliciesQuery {
        allContentfulPetysHome {
          nodes {
            footerRedireccionPoliticaDeDatos
          }
        }
      }
    `
  )
  const policiesLink =
    policiesInfo.allContentfulPetysHome.nodes[0]
      .footerRedireccionPoliticaDeDatos

  return (
    <div className="f-product-qualify">
      <div className="f-title-mobile-container">
        <SectionTitleMobile
          className="f-title-mobile"
          title="CALIFICAR"
          redirection={parentSlug + product.slug}
        />
      </div>

      <div className="f-product-qualify-container">
        <div className="f-breadcrum-position">
          <BreadCrumb mobile={isMobile} />
        </div>

        {!successSaveForm ? (
          <>
            <div className="f-product-qualify-content">
              <figure className="f-product-qualify-logo">
                <img src={LogoQualify} alt="Logo calificación" />
              </figure>

              <HeaderContentQuality product={product} />

              <div className="f-product-qualify-content-container">
                {activeStep <= 1 && (
                  <>
                    <p className="f-product-qualify-content-container-text">
                      {questions[activeStep].title}
                    </p>

                    {activeStep <= 0 && (
                      <div className="f-container-step">
                        <CustomInputText
                          fullWidth={true}
                          label="Tu correo"
                          inputProps={{
                            id: "email-input",
                            type: "email",
                            required: true,
                            autoFocus: true,
                            placeholder: "petys@petys.com",
                            value: email,
                          }}
                          onChange={handleChangeEmail}
                        />

                        <div className="f-product-qualify-content-container-check">
                          <CustomCheckbox
                            label="He leído y autorizo que me contacten a mi correo"
                            onChange={handleChangeTermsAndPolicies}
                            checked={temrsConditions}
                            inputProps={{ name: "terms-conditions" }}
                          />
                          <i
                            className="fal fa-exclamation-circle f-icon-popup"
                            onClick={handleClickPopup}
                          ></i>
                          <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClosePopup}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                          >
                            <div className="f-propover-terms">
                              <h4 className="f-propover-title">
                                ¿Por qué necesitan mi correo?
                              </h4>
                              <p className="f-propover-description">
                                Para mejorar tu experiencia con Petys. Si tienes
                                algún comentario de mejora, nos gustaría poder
                                contactarte para brindarte asesoría. Revisa acá
                                como manejamos tu información:{" "}
                                <ButtonC
                                  className="f-link f-propover-link"
                                  title="Ir a TyC"
                                  href={homeLink + "terminos-y-condiciones/"}
                                />
                              </p>
                            </div>
                          </Popover>
                        </div>
                        <div className="f-product-qualify-content-container-check">
                          <CustomCheckbox
                            label={
                              <>
                                He leído y acepto la{" "}
                                <a href={policiesLink} target={"_blank"}>
                                  política de datos personales
                                </a>
                              </>
                            }
                            onChange={handleChangeTermsAndPolicies}
                            checked={dataPolicies}
                            inputProps={{ name: "data-policies" }}
                          />
                        </div>
                        <DataPolicy />
                      </div>
                    )}
                    {activeStep === 1 && (
                      <div className="f-container-step">
                        <CustomInputText
                          fullWidth={true}
                          label="Tu nombre y apellido"
                          inputProps={{
                            id: "name-inpur",
                            placeholder: "Petys",
                            value: name,
                            autoFocus: true,
                            required: true,
                          }}
                          onChange={handleChangeName}
                        />
                      </div>
                    )}
                  </>
                )}

                {activeStep > 1 && activeStep < maxSteps - 2 && (
                  <>
                    <div className="f-rating-img-description">
                      <figure className="f-rating-img">
                        <img
                          src={questions[activeStep].imagenCategoria.file.url}
                          alt={
                            questions[activeStep].imagenCategoria.description
                          }
                        />
                      </figure>
                      <p className="f-rating-description">
                        {questions[activeStep].nombreCategoria}
                      </p>
                    </div>
                    <Rating
                      classes={{ root: "f-rating-form" }}
                      name="half-rating"
                      value={parseInt(frmPruebasApruebas[activeStep])}
                      onChange={(event, newValue) =>
                        handleChangeRating(event, newValue, activeStep)
                      }
                    />
                  </>
                )}

                {activeStep === maxSteps - 2 && (
                  <div className="f-container-step">
                    <CustomInputTextArea
                      fullWidth={true}
                      rows={9}
                      value={commentary}
                      label={questions[activeStep].title}
                      id="commentary"
                      autoFocus={true}
                      placeholder="Escribe aquí..."
                      maxWrite="Máx (300 carácteres)"
                      onChange={handleChangeCommentary}
                    />
                  </div>
                )}

                {activeStep === maxSteps - 1 && (
                  <div className="f-container-finish">
                    {questions[activeStep].icon}
                    <h3 className="f-finish-title">
                      {questions[activeStep].title}
                    </h3>
                    <p className="f-finish-description">
                      {questions[activeStep].description}
                    </p>
                  </div>
                )}

                <div className="f-product-qualify-buttons">
                  {activeStep > 0 && activeStep !== maxSteps - 1 && (
                    <ButtonC
                      className="f-btn-purple f-qualify-button-prev"
                      typeButton="button"
                      title="Atrás"
                      href=""
                      onClick={handleBack}
                    />
                  )}
                  <ButtonC
                    className="f-primary-purple f-qualify-button-next"
                    typeButton="button"
                    title={
                      activeStep !== maxSteps - 1 ? "Siguiente" : "Finalizar"
                    }
                    disabled={disabledButton}
                    onClick={e => {
                      if (activeStep === maxSteps - 1) {
                        setSuccessSaveForm(true)
                      } else if (activeStep === maxSteps - 2) {
                        handleSendForm(e)
                      } else {
                        handleNext(e)
                      }
                    }}
                  />
                </div>
              </div>

              <CustomProgressBar
                className="f-linear-progress"
                variant="determinate"
                value={progresBar}
              />
            </div>
          </>
        ) : (
          <div className="f-qualify-other-product">
            <RelatedQualify dataProduct={dataProduct} />
          </div>
        )}
      </div>
    </div>
  )
}
export default QualifyContent
