import React from 'react'
import { Checkbox, FormControlLabel, FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { latoBold, purple, contrastMedium } from '../../../styles/variables.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    color: purple,
    fontFamily: latoBold,
    fontSize: 13,

    '& error': {
      color: 'red'
    }
  },
  formControlLabel: {
    color: purple,
    fontFamily: latoBold,
    fontSize: 13,
    marginRight: 0,

    '& span': {
      fontFamily: latoBold,
      fontSize: 13,
    }
  },
  checkbox: {
    color: purple,
  },
  formHelperText: {
    position: 'absolute',
    bottom: -10,
    left: 0,
  },
}));

const CustomCheckbox = (props) => {

  const { error, errorText, label, className, checked, inputProps, inputRef, ...params } = props
  const classes = useStyles();

  return (
    <FormControl error={error} className={classes.root + (className ? " ".concat(className) : '')} {...params}>
      <FormControlLabel
        checked={checked}
        inputRef={inputRef}
        className={classes.formControlLabel}
        control={<Checkbox checked={checked} className={classes.checkbox} color="default" inputProps={inputProps} />}
        label={label}
      />
      {(error && errorText) && <FormHelperText className={classes.formHelperText}>{errorText}</FormHelperText>}
    </FormControl>
  )
}


export default CustomCheckbox;
