import React, { useState } from "react"
import useMobie from "../../shared/hooks/useMobile"
import Layout from "../../shared/components/organisms/layout/layout"
import QualifyContent from "../../components/organisms/qualify-content/qualify-content"
import SEO from "../../shared/components/atoms/seo/seo"

const QualifyTemplate = props => {
  const isMobile = useMobie()
  const { product, products, parentSlug } = props.pageContext

  const [modalActive, setmodalActive] = useState(null)

  return (
    <>
      <SEO
        title={product.metaTitulo ? product.metaTitulo : ""}
        description={
          product.metaDescripcion
            ? product?.metaDescripcion?.metaDescripcion
            : ""
        }
        slug={product.slug ? parentSlug + product.slug + "calificar/" : ""}
        meta={[
          { name: "no-follow", content: "true" },
          { name: "no-index", content: "true" },
        ]}
        lang="ES"
      />
      {isMobile ? (
        <QualifyContent
          isMobile={isMobile}
          product={product}
          parentSlug={parentSlug}
          dataProduct={products}
        ></QualifyContent>
      ) : (
        <Layout activeMenu={1} modalActive={modalActive}>
          <QualifyContent
            isMobile={isMobile}
            product={product}
            parentSlug={parentSlug}
            dataProduct={products}
          ></QualifyContent>
        </Layout>
      )}
    </>
  )
}

export default QualifyTemplate
