import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles, } from '@material-ui/core/styles';
import { green } from '../../../styles/variables.scss';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    position: "absolute",
    bottom: 0,
    width: '100%',
    height: 10,
    borderRadius: 0,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: green,
  },
}))(LinearProgress);

const CustomProgressBar = (props) => {
  return (
    <BorderLinearProgress {...props} />
  )
}


export default CustomProgressBar;
