import React from 'react';
import './header-content-qualify.scss';

const HeaderContentQualify = props => {

  const { product } = props;

  return (
    <div className="f-product-qualify-content-header">
      <figure className="f-product-qualify-content-img">
        <img src={product.imagenDelProducto.file.url} alt={product.imagenDelProducto.description}></img>
      </figure>
      <div className="f-product-qualify-content-text">
        <p className="f-product-qualify-content-category">Estás calificando:</p>
        <h1 className="f-product-qualify-content-title">{product.nombreDelProducto}</h1>
      </div>
    </div>
  );
};

export default HeaderContentQualify;
