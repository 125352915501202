import React, { useRef } from 'react';
import CardProduct from '../../../shared/components/atoms/card-product/card-product';
import Slider from "react-slick";
import Button from '../../../shared/components/atoms/button/button'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './relacted-qualify.scss';

const RelactedQualify = props => {

  const { dataProduct } = props;
  const slider = useRef(null);

  /*Custom arrow*/
  const SampleNextArrow = () => {
    return (
      <div className={"f-slider-control-next"} onClick={() => slider.current.slickNext()}>
        <i className="fas fa-chevron-right"></i>
      </div>
    );
  }

  /*Custom arrow*/
  const PrevCustomArrow = () => {
    return (
      <div className={"f-slider-control-prev"} onClick={() => slider.current.slickPrev()}>
        <i className="fas fa-chevron-left"></i>
      </div>
    );
  }

  const listItems = dataProduct.map((product, key) => {
    return <CardProduct className="f-qualify-cards" product={product} key={key} isQuality={true}></CardProduct>
  })

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <PrevCustomArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          centerPadding: "60px 0 0",
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "60px 0 0",
          infinite: true,
          dots: true,
          arrows: false,
        }
      },
    ]
  };

  return (
    <div className="f-relacted-qualify">
      <h2 className="f-relacted-qualify-title">Califica otro producto</h2>
      <Slider {...settings} ref={slider} className="f-slider-related-qualify">
        {listItems}
      </Slider>
      <Button className={"f-primary-purple f-related-qualify-button"} title="Ir al home" href="" />
    </div>
  );
};

export default RelactedQualify;